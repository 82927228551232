/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {useContext, useEffect, useState} from 'react';

import {ConfigContext} from '../context/config';
import summaryData from '../data.json';
import {LANGUAGE_MODEL_URL} from '../context/constants';
import { AnyARecord } from 'dns';
import { RollerShades } from '@mui/icons-material';
import OpenAI from "openai";
import { Thread } from 'openai/resources/beta/threads/threads';
import { get } from 'http';

/**
 * Represents a message object with an author and content.
 * @interface
 * @property {string} author - The author of the message.
 * @property {string} content - The content of the message.
 */
export interface MessageProps {
  author: string;
  content: string;
}

export interface ChoicesProps {
  message: {content:string};
}

/**
 * Represents an example object that defines the expected input and output for a
 * prompt.
 * @interface
 * @property {string} input.content - The content of the input for the example.
 * @property {string} output.content - The expected output content for the
 * example.
 */
export interface ExampleProps {
  input: {content: string};
  output: {content: string};
}

/**
 * Represents the properties for a prompt object, which contains a context,
 * examples, and a list of messages.
 * @interface
 * @property {string} [context] - The context for the prompt.
 * @property {ExampleProps[]} [examples] - An array of example objects that
 * define the expected input and output of the prompt.
 * @property {MessageProps[]} messages - An array of message objects that
 * represent the prompt's messages.
 */
export interface PromptProps {
  context?: string;
  examples?: ExampleProps[];
  messages: MessageProps[];
}


export interface OpenAIPromptProps {
  messages: {role:string, content:string}[];
}


/**
 * Represents the response object returned by the sendPrompt function.
 * @interface
 * @property {MessageProps[]} messages - An array of message objects that
 * represent the prompt's messages.
 * @property {MessageProps[]} candidates - An array of message objects that
 * represent the potential responses to the prompt.
 */
export interface SendPromptResponse {
  candidates: MessageProps[];
  messages: MessageProps[];
  choices:ChoicesProps[];
}

type LanguageModel = {
  sendMessage: (message: string, thread_id:string) => Promise<any>;
  getConversationHistory: (thread_id: string, assistant_id: string) => Promise<string>;
  createThread: () => Promise<Thread>;
  getAuthUserData: () => any;
};

const useLanguageModel = ():
    LanguageModel => {
      // const openai = new OpenAI({apiKey:process.env.OPENAI_API_KEY});
      const openai = new OpenAI({apiKey:'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',  dangerouslyAllowBrowser: true });
      // TODO , convert this to a function and export
      // const queryString = window.location.search;
      // const urlParams = new URLSearchParams(queryString);
      // let session = parseInt(urlParams.get('session_id')!);
      // let sessionData = urlParams.get('session')!;
      // let userData;
      // if (sessionData){
      //   var decodedString = atob(sessionData);
      //   userData = JSON.parse(decodedString);
      //   console.log("JSON Object: ", userData);
      // }

      // if (!session) {
      //   session = 2
      // }

      // const coacheeData = summaryData[2];
      // const [summary, setSummary] = useState(coacheeData.summary);
      // const [name, setName] = useState(coacheeData.name); 

      const getAuthUserData = function(){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let user64Data = urlParams.get('user')!;
        let userData;
        if (user64Data){
          var decodedString = atob(user64Data);
          userData = JSON.parse(decodedString);
          console.log("JSON Object: ", userData);
        }
        return userData;
      }

      const [name, setName] = useState(getAuthUserData().name); 


      const config = useContext(ConfigContext);

      const [messagesSize, setMessagesSize] = useState(0);

      let context = '';
      // let messages: MessageProps[] = [];
      let messages: any[] = [];


  
      const getPromptByMode = function() {


        const baseContext = `${config.state.personality} ${config.state.backStory}. ${config.state.knowledgeBase}
        You are an AI coach talking to a coachee called ${name}.`;
        return baseContext;

        // let currMode = getModeChange();
        // if (currMode === 1) {
        //   return `${baseContext} You are taking part in a new coaching conversation with a coachee. Your task is to:
        //   1. Greet the user.
        //   2. Establish rapport with the user, and find out a bit about them.
        //   2. Enquire about the purpose and goals of the coaching session from their perspective.
        //   3. Ask open-ended questions to understand the coachee's current situation and challenges.
        //   `;
        // } else if (currMode === 2) {
        //   return `${baseContext} You are in the middle phase of the coaching session. Your task is to:
        //   1. Explore the coachee's situation in depth.
        //   2. Ask contextual questions to enable the user to explore their situation, and identify personal insights.
        //   3. Respond to new or interesting information by the user with affirmations, relatable messages, and encouragement.
        //   4. Provide pathways for the coachee to brainstorm potential solutions or approaches for themselves.
        //   5. Ask powerful questions that promote self-reflection and discovery.`;
        // } else {
        //   return `${baseContext} You are in the wrap-up phase of the coaching session. Your task is to:
        //   1. Summarize and reiterate key insights and learnings from the session.
        //   2. Nudge the coachee create an action plan or next steps.
        //   3. Ask the coachee what they feel they have learned throughout the coaching session.
        //   4. Encourage commitment to specific actions or behavior changes.
        //   5. Schedule any follow-up sessions if needed.`;
        // } 
          
      }

      const getModeChange = function(){
         let currentMode = -1;
         if (messagesSize < 5){
           currentMode = 1;
         } else if (messagesSize < 15){
          currentMode = 2;
         } else {
          currentMode = 3;
         } 


    
        return currentMode;
       }

      const sendPrompt = async(prompt: OpenAIPromptProps, temperature: number, thread_id:string):
          Promise<any> => {

            let run = await openai.beta.threads.runs.createAndPoll(
              thread_id,
              { 
                // assistant_id: 'asst_e0KuvPl1h5TPWHd65OLyYaa4',//4o
                assistant_id: "asst_8mwt7g26DB0Xpg5wMkEqmUiN",
                instructions: getPromptByMode(),
              },
              {pollIntervalMs:200}
            );
      
            let formattedConversation = '';
            let current = '';
            if (run.status === 'completed') {
              const messages = await openai.beta.threads.messages.list(
                run.thread_id
              );

              let content = messages.data[0].content[0];
              if(content.type === 'text')
                current =  content.text.value
    
              messages.data.forEach((message) => {
                let content = message.content[0];
                if (content.type === 'text' && message.role === 'user') {
                  formattedConversation += `<div class="message messageCoachee"> ${content.text.value} </div>`;
                } else if (content.type === 'text' && message.role === 'assistant') {
                  formattedConversation += `<div class="message messageCoach"> ${content.text.value} </div>`;
                }
              });
              setMessagesSize((messages.data.length+1)/2); // +1 to account for the initial message (divbyzero)
    
              // await openai.beta.threads.runs.cancel(thread_id, run.id)
    
            } else {
              console.log(run.status);
            }
            return { conversation:formattedConversation, current:current};
          };

      // useEffect(() => {
      //   context = `Your task is to acting as a character that has this personality: "${
      //       config.state
      //           .personality}". Your response must be based on your personality. You have this backstory: "${
      //       config.state.backStory}". Your knowledge base is: "${
      //       config.state
      //           .knowledgeBase}". The response should be one single sentence only.`;
      // }, [config]);

      // useEffect(() => {
      //   context = `${config.state.personality} ${config.state.backStory} ${config.state.knowledgeBase}.  Here is a reference of our previous discussion:  ${summary}`;
      // }, [config]);

      useEffect(() => {
        context = `${config.state.personality} ${config.state.backStory}. ${config.state.knowledgeBase} You are talking to a coachee called ${name}`;
      }, [config]);


      const getConversationHistory = async(thread_id: string, assistant_id: string): Promise<string> => {

        let run = await openai.beta.threads.runs.createAndPoll(
          thread_id,
          { 
            assistant_id: assistant_id
          },
          {pollIntervalMs:10}
        );
  
        let formattedConversation = '';
        if (run.status === 'completed') {
          const messages = await openai.beta.threads.messages.list(
            run.thread_id
          );

          messages.data.forEach((message) => {
            let content = message.content[0];
            if (content.type === 'text' && message.role === 'user') {
              formattedConversation += `<div class="message messageCoachee"> ${content.text.value} </div>`;
            } else if (content.type === 'text' && message.role === 'assistant') {
              formattedConversation += `<div class="message messageCoach"> ${content.text.value} </div>`;
            }
          });

          // await openai.beta.threads.runs.cancel(thread_id, run.id)

        } else {
          console.log(run.status);
        }
        console.log('General Kenobi');
        return formattedConversation;
      };

      const createThread = async(): Promise<Thread> => {
          return await openai.beta.threads.create();
      }

      const sendMessage = async(message: string, thread_id:string): Promise<string> => {
        // const content = `Please answer within 100 characters. {${
        //     message}}. The response must be based on the personality, backstory, and knowledge base that you have. The answer must be concise and short.`;

        // const prompt: PromptProps = {
        //   context: context,
        //   messages: messages.concat([{author: '0', content}]),
        // };

        // const assistant = await openai.beta.assistants.create({
        //   name: "AI Coach 3.5",
        //   instructions: context,
        //   tools: [],
        //   model: "gpt-3.5-turbo",
        // });

        // const thread = await openai.beta.threads.create();
        const msg = await openai.beta.threads.messages.create(
          // thread.id,
          thread_id,
          //'thread_o3i6nXkaiQQccM13k6ck8xk7',
          {role: 'user', content:message}
        );
        

        const prompt: OpenAIPromptProps = {
          messages: messages.concat([{role: 'user', content:message}, {role:'system', content:getPromptByMode()} ]),
        };
        

        // const response = await sendPrompt(prompt, 0.25, thread.id);
        const response = await sendPrompt(prompt, 0.25, thread_id);

        // messages = response.messages.concat(response.candidates[0]);
        // return response.candidates[0].content;
        // message = response.choices[0].message.content

        return response;
        
        // return "what are you saying?";//response.messages[0].content;
      };

      return {
        sendMessage,
        getConversationHistory,
        createThread,
        getAuthUserData
      }
    }

export default useLanguageModel;
