/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { KeyboardVoiceOutlined, MicOffOutlined, Pause, SettingsOutlined } from '@mui/icons-material';
import { AppBar, Box, CardMedia, IconButton, Toolbar, Typography, Button, Stack } from '@mui/material';
import React, { useEffect, useState , ReactNode, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import useAvatarImage from '../apis/avatarImage';
import useLanguageModel from '../apis/languageModel';
import useSpeechRecognition, { CharacterState } from '../apis/speechRecognition';
import useTextToSpeech from '../apis/textToSpeech';
import useStyle, {COLORS} from './styles';
import { Canvas } from '@react-three/fiber'
import * as talkingHead from '../apis/talkingHead';
import {Doggo} from '../components/ThreeJS/Doggo07';
import {ZEPETO_TORSO_3} from '../components/ThreeJS/ZEPETO_TORSO_3';
import {GRV} from '../components/ThreeJS/grv';
import ReactMarkdown from 'react-markdown'
import summaryData from '../data.json';
import rehypeRaw from "rehype-raw";
import Conversation from './chattext';



const useZepetoModel = false;


function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

function CueAnalysis(text: Text) {
  // Figure out cues from text
}

interface Props {
  children?: ReactNode
}

const MarkdownRenderer = ({ children }: Props) => {
  return (
    <ReactMarkdown components={{ a: LinkRenderer }}>
      {children?.toString()}
    </ReactMarkdown>
  );
};

// const markdown1 = '# This is a H1  \n## This is a H2  \n#### This is a H4';
// const markdown2 = '### Section \nTesting out some *formatting* of **various** kinds. \n> Quoting text   \n\nA link to [Google](https://www.google.com).';


const Character: React.FC = () => {

  const navigate = useNavigate();
  const { sendMessage, getConversationHistory, createThread, getAuthUserData} = useLanguageModel();
  
  let userData = getAuthUserData()

  const [markdown, setMarkdown] = useState(`Welcome ${userData.name}`);
  const {
    characterState,
    bars,
    setCharacterState,
    onMicButtonPressed,
    setOnSpeechFoundCallback,
  } = useSpeechRecognition();
  const { convert, setOnProcessCallback } = useTextToSpeech();
  const { storedImage } = useAvatarImage();
  const [transcript, setTranscript] = useState<String[]>(['You:', '<div class="introMessage"><img src="Hand.png" /><p>Hey there.</p><span>Use the mic button below to start a conversation.</span></div>']);
  // getConversationHistory('thread_hGXK6IXuSaVjtWt20zSoRnWl','asst_8mwt7g26DB0Xpg5wMkEqmUiN').then((res) => {
  //   setTranscript(['You:', res]);
  // });
  // const [transcript, setTranscript] = useState<String[]>(['You:', '']);
  const {boxWidth} = useStyle();
  talkingHead.runBlendshapesDemo(useZepetoModel);

  useEffect(() => {
    let t:string;
    createThread().then((result) => {
      console.log(result.id);
      t = result.id;
    });
    setOnProcessCallback((audioData: Float32Array) => {
      talkingHead.registerCallback(audioData);
    });
    setOnSpeechFoundCallback((transcription: string) => {
      // setTranscript(['You:', transcription]);

      // insert function to analyze user message for cues, then append meta instructions

      sendMessage(transcription, t).then((result) => {
        setTranscript(['Coach:', result.conversation]);
        convert(result.current).then(() => {
          setCharacterState(CharacterState.Idle);
        });
      });
    });
  }, []);

  const handleCustomizeButtonClick = () => {
    if (characterState == CharacterState.Idle) {
      navigate('/personality');
    }
  };

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  const characterStateIcon = {
    [CharacterState.Idle]: (
      <IconButton
        className="shadow-box"
        onClick={onMicButtonPressed}
        aria-label="Start Recording"
        sx={{
          width: '10vh',
          height: '10vh',
          marginTop: '30px',
          padding: '16px',
          borderRadius: '50%',
          color: COLORS.primary,
          backgroundColor: COLORS.bgcolor,
          '&:hover': {
            backgroundColor: COLORS.bgcolor,
            '@media (hover: none)': {
              backgroundColor: COLORS.bgcolor,
            },
          },
        }}>
        <KeyboardVoiceOutlined sx={{fontSize: '40px'}} />
      </IconButton>
    ),
    [CharacterState.Listening]: (
      <IconButton
        className="shadow-box"
        onClick={onMicButtonPressed}
        color="error"
        aria-label="Stop Recording"
        sx={{
          width: '10vh',
          height: '10vh',
          marginTop: '30px',
          padding: '16px',
          borderRadius: '50%',
          backgroundColor: COLORS.bgcolor,
          '&:hover': {
            backgroundColor: COLORS.bgcolor,
            '@media (hover: none)': {
              backgroundColor: COLORS.bgcolor,
            },
          },
        }}>
        <Pause sx={{fontSize: '40px'}} />
      </IconButton>
    ),
    [CharacterState.Speaking]: (
      <IconButton
        className="shadow-box"
        onClick={onMicButtonPressed}
        color="default"
        aria-label="Recording Disabled"
        sx={{
          width: '10vh',
          height: '10vh',
          marginTop: '30px',
          padding: '16px',
          borderRadius: '50%',
          backgroundColor: 'grey.400',
          '&:hover': {
            backgroundColor: 'grey.500',
            '@media (hover: none)': {
              backgroundColor: 'grey.400',
            },
          },
        }}>
        <MicOffOutlined sx={{fontSize: '40px'}} />
      </IconButton>
    ),
  };

  return (

    <div className='Content'>
    <div className='Content-left'>


    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingLeft: '5vh',
        paddingRight: '5vh',
        bgcolor: COLORS.bgcolor,
      }}>

      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{width: boxWidth, alignSelf: 'center', display: 'none'}}>
        <Toolbar className="tool-bar">
          <Box
            component="div"
            className="shadow-back-button"
            sx={{ justifyContent: 'center', color: COLORS.bgcolor, display: 'none'}}>
            <IconButton
              onClick={handleCustomizeButtonClick}
              aria-label="fullscreen">
              <SettingsOutlined
                sx={{fontSize: '3vh', color: COLORS.primary}}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          position: 'relative',
          height: '100%',
        }}>
        <Box
          component="div"
          className="avatarBox"
          sx={{
            width: '20vh',//boxWidth,
            height: '20vh',
            boxSizing: 'border-box',
            overflow: 'visible',
            margin: '24px 0 24px 0',
            bgcolor: '#FFFFFF',
            position: 'relative',
          }}>
          {storedImage === '' || storedImage === null ? (
            useZepetoModel ?
            <Canvas
              camera={{ fov:45, rotation: [0,0,0], position: [0, 0, 15] }}
            >
              <pointLight position={[0, 0, 10]} intensity={.03}/>
              <ambientLight intensity={1.} />
              <GRV></GRV>
            </Canvas>
            :
            <Canvas
              camera={{ fov:45, rotation: [0,0,0], position: [0, 0, 10] }}
              style={{ backgroundColor: '#FAD972' }}
            >
              <pointLight position={[0, 0, 10]} intensity={.03}/>
              <Doggo></Doggo>
            </Canvas>
          ) : (
            <CardMedia
              id="talkingHeadIframe"
              height="100%"
              component="img"
              image={storedImage}
              alt="Uploaded Image"
            />
          )}
          <span>Coach</span>
        </Box>
        {/* <Stack direction="column" spacing={1}>  
          <Button variant="outlined" style={{borderRadius: '50vh', textTransform: 'none', fontWeight: '700', fontFamily: 'Plus Jakarta Sans, sans-serif'}} onClick={() => {  
                convert('Picking up from our last discussion, leadership will create maximum impact. Watch the video on leadership and ask me any follow up questions.');
                setMarkdown(`${coacheeData.learning!} \n --- \n ${markdown}`);
                setTranscript(['Coach:', 'Picking up from our last discussion, leadership will create maximum impact. Watch the video on leadership and ask me any follow up questions.']);
              }}>Can you explain Leadership? </Button> 
          <Button variant="outlined" style={{borderRadius: '50vh', textTransform: 'none', fontWeight: '700', fontFamily: 'Plus Jakarta Sans, sans-serif'}} onClick={() => {  
                convert('Lets follow up from our last discussion.  What steps were you able to take to create positive impact');
                // setMarkdown(`${coacheeData.learning!} \n --- \n ${markdown}`);
                setTranscript(['Coach:', 'Lets follow up from our last discussion.  What steps were you able to take to create positive impact?']);
              }} >What difference did I make in the lives that I touch?</Button> 
        </Stack>   */}
        {/* <Box
          component="div"
          sx={{
            width: boxWidth,
            textAlign: 'left',
            boxSizing: 'content-box',
            overflow: 'hidden',
          }}>
          <Typography sx={{fontFamily: 'Plus Jakarta Sans, sans-serif'}}>{transcript[0]}</Typography>
        </Box> */}

        <Box
          component="div"
          sx={{
            width: boxWidth,
            height: '55vh',
            verticalAlign: 'middle',
            boxSizing: 'content-box',
            margin: '2vh 0',
          }}>
          {/* <Typography
            className="display-linebreak"
            style={{ color: COLORS.primary }}
            sx={{
              padding: '0.8vh',
              margin: '1.2vh',
              textAlign: 'left',
              height: '11vh',
              overflow: 'scroll',
              '&::-webkit-scrollbar': {
                width: '1.5px',
                height: '0',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#AAA',
                borderRadius: '0.7px',
              },
              borderRadius: '4vh',
              fontFamily: 'Plus Jakarta Sans, sans-serif',
              fontSize: '14px',
            }}>
            {transcript[1]}
          </Typography> */}

          <Conversation chatContent={transcript[1]}/>

        </Box>

        <Box
          component="div"
          sx={{
            justifyContent: 'center',
            paddingTop: '0vh',
            transform: 'translate(15px, -30px)',
          }}>
          {characterStateIcon[characterState]}
          <Box component="div" className={`bar-container ${characterState != CharacterState.Listening ? 'hidden' : ''}`}>
            <Box component="div" ref={(el: HTMLDivElement | null) => (bars.current[0] = el)} className="bar" />
            <Box component="div" ref={(el: HTMLDivElement | null) => (bars.current[1] = el)} className="bar middle" />
            <Box component="div" ref={(el: HTMLDivElement | null) => (bars.current[2] = el)} className="bar" />
          </Box>
        </Box>
      </Box>
    </Box>
    </div>
    <div className='Content-right'>
      <div className="content-view-box">
        <h2>Welcome to AI Coach</h2>
        <p>You can talk to AI coach the way you would converse with your own coach. AI Coach is here to ensure you can go through your improvmement process on your own terms, in your own time.</p>
        <p>Start a conversation by pressing the mic button.</p>
      </div>
    </div>
  </div>
  );
};

export default Character;
