/**
 * Copyright 2023 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { Layout } from './components/Layout';
import { ConfigProvider } from './context/config';
import { reportWebVitals } from './reportWebVitals';

const Root: React.FC = () => {
  useEffect(() => {
    // if (window.top === window && document.referrer.indexOf('https://app.coachoptima.com') !== 0) {
    //   // Page is not in an iframe
    //   document.body.innerHTML = "Unauthorized access.";
    // }
  }, []);

  return (
    <ConfigProvider>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Layout>
          <App />
        </Layout>
      </React.Suspense>
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Root />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
