import React from 'react';

interface ConversationProps {
  chatContent: String;
}

class Conversation extends React.Component<ConversationProps> {
  render() {
    return (
      <div
        style={{display: 'flex', flexDirection: 'column-reverse', width: '100%', height: '100%', overflowY: 'scroll',}}
        dangerouslySetInnerHTML={{ __html: this.props.chatContent }}
      />
    );
  }
}

export default Conversation;