import React from 'react';

class Landing extends React.Component {
    render() {
      return (
        <div className="coacheebox">
		<h2>Coachees</h2>
		<div className="coachee">
			<div className="nametag">
				<div className="nameicon color1">DS</div>
				<p>Deepksha Sharma</p>
			</div>
			<div className="coachlink">
				<a href="/coach?user=eyJuYW1lIjoiRGVlcGtzaGEgU2hhcm1hIn0=">Talk to Coach</a>
			</div>
		</div>
		<div className="coachee">
			<div className="nametag">
				<div className="nameicon color2">RD</div>
				<p>Richa Desai</p>
			</div>
			<div className="coachlink">
				<a href="/coach?user=2">Talk to Coach</a>
			</div>
		</div>
		<div className="coachee">
			<div className="nametag">
				<div className="nameicon color3">MK</div>
				<p>Meera Kulkarni</p>
			</div>
			<div className="coachlink">
				<a href="/coach?user=3">Talk to Coach</a>
			</div>
		</div>
		<div className="coachee">
			<div className="nametag">
				<div className="nameicon color4">KJ</div>
				<p>Kavita Joshi</p>
			</div>
			<div className="coachlink">
				<a href="/coach?user=4">Talk to Coach</a>
			</div>
		</div>
		<div className="coachee">
			<div className="nametag">
				<div className="nameicon color5">VR</div>
				<p>Vikram Rao</p>
			</div>
			<div className="coachlink">
				<a href="/coach?user=5">Talk to Coach</a>
			</div>
		</div>
	</div>
      );
    }
  }

export default Landing;